<template>
  <date-picker
    v-model="datetime"
    type="datetime"
    placeholder="YYYY/MM/DD - HH:MM"
    :show-time-panel="false"
    popup-class="date-picker-popup"
    :append-to-body="false"
    :formatter="momentFormat"
    :confirm="true"
    confirm-text="確定する"
    :lang="lang"
    :open="isOpen"
    :clearable="false"
    :disabled-date="disabledRange"
    @confirm="onConfirm"
    @pick="pickDate"
  >
    <template v-slot:input>
      <the-mask
        v-model="valueInput"
        mask="####/##/## ##:##"
        placeholder="YYYY/MM/DD - HH:MM"
        type="tel"
        @focus.native="onFocus"
        @click="onFocus"
        readonly
      />
    </template>
    <template v-slot:icon-calendar>
      <div
        @click="onFocus"
        class="
          d-flex
          justify-content-center
          align-items-center
          icon-date-picker
        "
      >
        <b-icon icon="calendar4"></b-icon>
      </div>
    </template>
    <template v-slot:footer>
      <div class="w-100 d-flex row-time">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <div class="label-time f-w6">プレー開始時間</div>
          <div class="d-flex input-select-time">
            <div class="postion-relative">
              <div
                class="
                  input-time
                  position-relative
                  d-flex
                  justify-content-start
                  align-items-center
                "
                @click="showHour"
              >
                {{ hour }}
                <b-icon
                  icon="play-fill"
                  class="position-absolute icon-dropdown"
                ></b-icon>
              </div>
              <transition name="slide">
                <ul class="list" v-if="isShowHour">
                  <li
                    v-for="(hour, index) in hourOption"
                    :key="index"
                    @click="chooseHour(index)"
                    class="hour-option"
                  >
                    {{ hourOption[index] }}
                  </li>
                </ul>
              </transition>
            </div>
            <label class="dot mb-0">時頃</label>
          </div>
        </div>
      </div>
      <hr class="solid" />
      <div class="row-button w-100 d-flex">
        <b-button @click="onCancel" class="btn-footer cancel f-w3"
          >キャンセル</b-button
        >
      </div>
    </template>
  </date-picker>
</template>

<script>
import "vue2-datepicker/locale/ja";
export default {
  name: "Datetimepicker",
  props: {
    initValue: [Date, null]
  },
  components: {},
  data() {
    return {
      isShowHour: false,
      isShowMinute: false,
      datetime: null,
      cancelDate: null,
      valueInput: "",
      lang: "ja",
      form: {
        date: new Date(),
        number: ""
      },
      attrs: [],
      isChooseHour: 0,
      hourOption: [],
      minuteOption: [],
      buttons: [
        { caption: "AM", state: true },
        { caption: "PM", state: false }
      ],
      hour: "03",
      minute: "00",
      momentFormat: {
        stringify: date => {
          return date ? this.$dayjs(date).format("YYYY/MM/DD HH") : "";
        },
        parse: value => {
          return value ? this.$dayjs(value, "YYYY/MM/DD HH").toDate() : null;
        }
      },
      isOpen: false
    };
  },
  methods: {
    disabledRange: function(date) {
      if (Number(new Date().getHours()) <= 18) {
        return (
          date < this.addDays(new Date(), 0) ||
          date > this.addDays(new Date(), 45)
        );
      }
      return (
        date < this.addDays(new Date(), 1) ||
        date > this.addDays(new Date(), 46)
      );
    },
    addDays(dateObj, numDays) {
      dateObj.setDate(dateObj.getDate() + numDays);
      return dateObj;
    },
    chooseHour(index) {
      this.isShowHour = false;
      this.hour = this.hourOption[index];
      this.isChooseHour = 1;
    },
    chooseMinute(index) {
      this.isShowMinute = false;
      this.minute = this.minuteOption[index];
      this.isChooseHour = 1;
    },
    showMinute() {
      this.isShowMinute = !this.isShowMinute;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("minute-option");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.minute) {
            listOptions[i].style.backgroundColor = "#ACABAB";
          }
        }
      });
    },
    showHour() {
      this.isShowHour = !this.isShowHour;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("hour-option");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.hour) {
            listOptions[i].style.backgroundColor = "#ACABAB";
          }
        }
      });
    },
    changeTime(index) {
      this.buttons[index ? 0 : 1].state = false;
    },
    onConfirm(date) {
      if (this.isChooseHour == 0) {
        this.$toast("時間の入力をお願いします", "通知", "danger");
      } else {
        if (date) {
          this.datetime = this.$dayjs(date)
            .hour(this.buttons[0].state ? this.hour : Number(this.hour) + 12)
            .minute(this.minute).$d;
        } else {
          this.datetime = this.$dayjs(new Date())
            .hour(this.buttons[0].state ? this.hour : Number(this.hour) + 12)
            .minute(this.minute).$d;
        }
        this.onClose();
      }
    },
    setTime(date = "", isBlur = false) {
      if (isBlur && !this.datetime) return;
      let currentDate = date || this.datetime;
      if (currentDate) {
        let hour = this.$dayjs(currentDate).hour();
        this.hour = this.formatHour(hour);
        // this.minute = this.formatMinute(this.$dayjs(currentDate).minute());
      }
    },
    setHour() {
      this.hour = this.formatHour(this.hour);
    },
    setMinute() {
      this.minute = this.formatMinute(this.minute);
    },
    formatHour(value) {
      if (String(value).length === 1) {
        return `0${value}`;
      }
      return value || "00";
    },
    formatMinute(value) {
      if (Number(value) > 59) {
        return "59";
      } else if (Number(value < 0)) {
        return "00";
      } else if (String(value).length === 1) {
        return `0${value}`;
      }

      return value || "00";
    },
    // closeDatePicker() {
    //   document.getElementById(
    //     "select-time__popup___BV_modal_content_"
    //   ).style.height = "unset";
    //   this.isOpen = true;
    // },
    onFocus() {
      document.getElementById(
        "select-time__popup___BV_modal_content_"
      ).style.height = "550px";
      if (!this.cancelDate && !this.datetime) {
        document.getElementsByClassName("mx-datepicker")[0].className =
          "mx-datepicker not-time";
        // this.setTime(new Date());
      } else {
        document.getElementsByClassName("mx-datepicker")[0].className =
          "mx-datepicker";
      }
      this.isOpen = true;
    },
    pickDate(date) {
      this.cancelDate = date;
      if (!document.getElementsByClassName("today")[0]) return;
      if (
        this.$dayjs(date).format("YYYY-MM-DD") !==
        this.$dayjs().format("YYYY-MM-DD")
      ) {
        document.getElementsByClassName("today")[0].style.backgroundColor =
          "white";
        document.getElementsByClassName("today")[0].style.color = "black";
      } else {
        document.getElementsByClassName("today")[0].style.backgroundColor =
          "#e17c2f";
        document.getElementsByClassName("today")[0].style.color = "white";
      }
    },
    onClose() {
      this.isOpen = false;
      document.getElementById(
        "select-time__popup___BV_modal_content_"
      ).style.height = "auto";
    },
    onCancel() {
      this.isOpen = false;
      document.getElementById(
        "select-time__popup___BV_modal_content_"
      ).style.height = "auto";
    }
    // onBlur(event) {
    //   const value = event.target.value;
    //   const format = "YYYY/MM/DD HH:mm";
    //   if (this.$dayjs(value, format).format(format) === value) {
    //     this.datetime = this.$dayjs(value).$d;
    //     this.setTime("", true);
    //   } else {
    //     this.datetime = this.$dayjs().$d;
    //     this.valueInput = this.$dayjs().format("YYYY/MM/DD HH:mm");
    //     this.setTime("", true);
    //   }
    // }
  },
  created() {
    this.datetime = this.initValue;
    if (this.datetime) {
      this.setTime();
      this.isChooseHour = 1;
    }
    for (let i = 3; i <= 19; i++) {
      if (i < 10) {
        i = "0" + i;
      }
      this.hourOption.push(i);
    }

    for (let i = 0; i <= 59; i++) {
      if (i < 10) {
        i = "0" + i;
      }
      this.minuteOption.push(i);
    }
  },
  watch: {
    datetime: function(val) {
      if (val) {
        this.valueInput = this.$dayjs(val).format("YYYY/MM/DD HH:00");
      }
      this.$emit("onChange", val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/datetimePicker.scss";
.list {
  position: absolute;
  width: 88px;
  max-height: 90px;
  z-index: 10;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.2s ease-in-out;
  li {
    background: white;
    padding: 3px 5px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    color: #000;
  }
  li:hover {
    background-color: #f5f5f5;
  }
}
.list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
@media screen and (min-width: 1200px) {
  .list {
    width: 108px;
  }
}
</style>
