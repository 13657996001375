<template>
  <div class="area text-center">
    <form @submit="onSubmit">
      <div class="area__title f-w6">ピックアップエリア</div>
      <div class="area__sub-title f-w3">都道府県を選択してください。</div>
      <div class="position-relative">
        <input
          @click="showOptions()"
          readonly
          :value="getName()"
          class="area__input f-w3"
        />
        <!-- <b-icon
          :icon="showOption ? 'caret-up-fill' : 'caret-down-fill'"
          scale="1"
          class="position-absolute icon-input"
          @click="showOptions()"
        /> -->
      </div>
      <div class="option f-w3">
        <div v-for="option in commonData.golfAreas" :key="option.golf_area_id">
          <p
            :class="{
              active: area === option.golf_area_id,
              disable:
                $route.query.prefecture &&
                option.name != $route.query.prefecture,
              bold:
                $route.query.prefecture &&
                option.name == $route.query.prefecture
            }"
            class="value-option"
            :id="option.name"
            @click="chooseOption(option.golf_area_id)"
          >
            {{ option.name }}
          </p>
        </div>
      </div>
      <button class="btn-submit f-w3" type="submit">確定する</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SelectArea",
  data() {
    return {
      area: "",
      showOption: true
    };
  },
  methods: {
    showOptions() {
      // this.showOption = !this.showOption;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("value-option");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.area) {
            listOptions[i].style.backgroundColor = "#B9B9B9";
            listOptions[i].scrollIntoView();
          }
        }
      });
    },
    chooseOption(id) {
      this.area = id;
      // this.showOption = !this.showOption;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchOrder.golf_area_id = this.area;
      this.$emit("close-modal", "");
    },
    scroll() {
      let targetEle = document.getElementById("東京都");
      let pos = targetEle.style.position;
      let top = targetEle.style.top;
      targetEle.style.position = "relative";
      targetEle.style.top = "-170px";
      targetEle.scrollIntoView({ behavior: "smooth", block: "start" });
      targetEle.style.top = top;
      targetEle.style.position = pos;
    },
    getName() {
      return (
        this.commonData.golfAreas &&
        this.commonData.golfAreas.find(el => el.golf_area_id === this.area)
          ?.name
      );
    }
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials"
    })
  },
  created() {
    this.area = this.searchOrder.golf_area_id;
    setTimeout(() => {
      this.scroll();
    }, 500);
  }
};
</script>

<style lang="scss">
@import "../assets/scss/selectArea.scss";
@import "../assets/scss/_fontFamily.scss";
.disable {
  opacity: 0.5;
  pointer-events: none;
}
.bold {
  font-weight: 600 !important;
}
</style>
