var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"golf-level text-center"},[_c('form',{on:{"submit":_vm.onSubmit}},[_vm._m(0),_vm._l((_vm.commonData.femaleGolfRanks),function(item,index){return _c('span',{key:index,staticClass:"gold-level-item f-w3",class:{
        actived: _vm.selected === index || _vm.selectedTwo === index,
        'can-actived':
          (_vm.selected !== undefined &&
            (_vm.selected - 1 === index || _vm.selected + 1 === index)) ||
          (_vm.selectedTwo !== undefined &&
            (_vm.selectedTwo + 1 === index || _vm.selected - 1 === index)),
        disable:
          _vm.selected !== index &&
          _vm.selected + 1 !== index &&
          _vm.selected - 1 !== index &&
          _vm.selected !== undefined
      },on:{"click":function($event){return _vm.selectedRank(index)}}},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"f-w3 pay_number"},[_vm._v(" "+_vm._s(item.avg_score_standard > 140 ? "140以上" : item.avg_score_standard)+" ")])])}),_c('button',{staticClass:"btn-submit f-w3",attrs:{"type":"submit"}},[_vm._v("確定する")])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"golf-level__title f-w6"},[_vm._v(" ゴルフレベル "),_c('div',{staticClass:"golf-level__title--note f-w3"},[_vm._v(" ※連続する二つのレベルを指定できます。 ")])]),_c('div',{staticClass:"title-option-level f-w3 d-flex justify-content-between"},[_c('label',{staticClass:"golf-rank",attrs:{"for":""}},[_vm._v("ゴルフレベル")]),_c('label',{staticClass:"basic-charge",attrs:{"for":""}},[_vm._v("アベレージ")])])])}]

export { render, staticRenderFns }