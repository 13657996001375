<template>
  <button class="btn order-btn">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "OrderButton",
  props: {
    title: {
      type: String,
      require: true
    }
  }
};
</script>

<style lang="scss" scoped>
.order-btn {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background-color: #464d77;
  font-family: "Hiragino Kaku Gothic Pro W3";
  color: #fff;
}
</style>
