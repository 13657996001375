<template>
  <div class="select-time text-center">
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group
        id="date"
        label="ラウンド日時"
        label-for="input-1"
        class="label-form text-left f-w6"
      >
        <DatetimePicker :initValue="form.date" @onChange="form.date = $event" />
      </b-form-group>
      <div class="d-flex justify-content-between align-items-center label-text">
        <p class="mb-0 f-w6">お客様の人数</p>
        <p class="mb-0 text-note f-w3">※キャストの人数を除く</p>
      </div>
      <b-form-group
        id="number"
        label-for="input-1"
        class="label-form select-time__input text-left f-w6"
      >
        <div class="position-relative number-customer">
          <input
            @click="showOptions()"
            readonly
            :value="form.number ? form.number + '人' : ''"
            class="number-customer__input f-w3"
          />
          <b-icon
            :icon="showOption ? 'caret-up-fill' : 'caret-down-fill'"
            scale="1"
            class="position-absolute icon-input"
            @click="showOptions()"
          />
        </div>
        <div v-if="showOption" class="option f-w3">
          <div v-for="index in MAX_NUMBER_OF_OPTION" :key="index">
            <p
              :class="form.number === index ? 'active' : ''"
              class="text-center value-option"
              @click="chooseOption(index)"
            >
              {{ index }}人
            </p>
          </div>
        </div>
      </b-form-group>
      <OrderButton title="確定する" />
    </b-form>
  </div>
</template>

<script>
import OrderButton from "./OrderButton.vue";
import { mapGetters } from "vuex";
import { MAX_NUMBER_OF_OPTION } from "@/utils/const";
import DatetimePicker from "../Common/DatetimePicker.vue";
export default {
  name: "OrderSelectTimePopup",
  components: {
    OrderButton,
    DatetimePicker
  },
  data() {
    return {
      MAX_NUMBER_OF_OPTION: MAX_NUMBER_OF_OPTION,
      showOption: false,
      valueInput: "",
      form: {
        date: new Date(),
        number: ""
      },
      attrs: []
    };
  },
  methods: {
    showOptions() {
      this.showOption = !this.showOption;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("value-option");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.form.number) {
            listOptions[i].style.backgroundColor = "#B9B9B9";
            listOptions[i].scrollIntoView();
          }
        }
      });
    },
    chooseOption(number) {
      this.form.number = number;
      this.showOption = !this.showOption;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchOrder.scheduled_meeting_datetime = this.form.date;
      this.searchOrder.male_participants = this.form.number;
      // console.log(this.form.date);
      this.$emit("close-modal", "");
    },
    onReset() {}
  },
  created() {
    this.form.date = this.searchOrder.scheduled_meeting_datetime;
    this.form.number = this.searchOrder.male_participants;
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/men/OrderSelectTimePopup.scss";
</style>
