<template>
  <div class="container order" fluid>
    <template v-if="isLoading">
      <AppLoading />
    </template>
    <template v-else>
      <div ref="header" class="header" style="position: fixed; top: 0">
        <div class="header-back__button">
          <ButtonBack />
        </div>
        <div class="header-title f-w6">Superオファーを作成する</div>
        <!-- <button
          @click="$router.push({ name: 'Notification' })"
          class="btn btn-notify"
        >
          <img src="@/assets/image/nav_icon/bell.svg" />
          <span v-if="user && user.numberNotiUnread" class="_index f-w3 fs-8">
            {{ user.numberNotiUnread }}
          </span>
        </button> -->
      </div>
      <NotifyHeader />
      <div id="content-scroll" class="content-scroll">
        <b-row class="content d-flex flex-wrap" :class="direct ? 'direct' : ''">
          <b-col class="btn-left" cols="12" xl="6">
            <button
              id="clock"
              class="
                wrapper
                round-time-number-customer
                d-flex
                justify-content-between
                align-items-center
                text-right
              "
              :class="
                searchOrder.scheduled_meeting_datetime &&
                searchOrder.male_participants
                  ? 'active'
                  : ''
              "
              v-b-modal.select-time__popup
            >
              <div class="icon-check">
                <img
                  :src="require('@/assets/image/create_order/calendar.svg')"
                  alt="Time"
                />
              </div>
              <div class="wrapper-info time-number-customer">
                <div
                  class="title-info time-number-title f-w3 fs-14"
                  :class="
                    searchOrder.scheduled_meeting_datetime ||
                    searchOrder.male_participants
                      ? 'f-w6'
                      : ''
                  "
                >
                  ラウンド日時/お客様人数
                </div>
                <div
                  class="value-info time-number f-w3"
                  :class="
                    searchOrder.scheduled_meeting_datetime ||
                    searchOrder.male_participants
                      ? 'f-w6'
                      : ''
                  "
                >
                  {{
                    (searchOrder.scheduled_meeting_datetime &&
                      $dayjs(searchOrder.scheduled_meeting_datetime).format(
                        "M月D日 H時頃"
                      )) ||
                      "..."
                  }}
                  ／
                  {{
                    searchOrder.male_participants
                      ? searchOrder.male_participants + "人"
                      : "..."
                  }}
                </div>
              </div>
            </button>
          </b-col>
          <b-col class="btn-right" cols="12" xl="6">
            <div class="d-flex w-100 none-margin">
              <b-col class="none-padding-right" cols="7" xl="6">
                <button
                  id="calendar"
                  class="
                    wrapper
                    plan-info-wrap
                    d-flex
                    justify-content-between
                    align-items-center
                    text-right
                  "
                  :class="getPlanName() != '...' ? 'active' : ''"
                  v-b-modal.select-plan__popup
                >
                  <div class="icon-check">
                    <img
                      :src="require('@/assets/image/create_order/file.svg')"
                      alt="Time"
                    />
                  </div>
                  <div class="wrapper-info plan-info">
                    <div
                      class="title-info plan-title f-w3"
                      :class="getPlanName() != '...' ? 'f-w6' : ''"
                    >
                      プラン
                    </div>
                    <div
                      class="value-info plan f-w3"
                      :class="getPlanName() != '...' ? 'f-w6' : ''"
                    >
                      {{ getPlanName() }}
                    </div>
                  </div>
                </button>
              </b-col>
              <!-- :disabled="
                    !searchOrder.plan_id ||
                    searchOrder.plan_id != 1 &&
                    searchOrder.plan_id != 2
                  " -->
              <b-col class="none-padding" cols="5" xl="6">
                <button
                  id="location"
                  class="
                    wrapper
                    area-info-wrap
                    d-flex
                    justify-content-between
                    align-items-center
                    text-right
                  "
                  :disabled="!searchOrder.plan_id || checkMeeting() == 2"
                  :class="getAreaName() != '...' ? 'active' : ''"
                  v-b-modal.select-area__popup
                >
                  <div class="icon-check">
                    <img
                      :src="require('@/assets/image/create_order/location.svg')"
                      alt="Time"
                    />
                  </div>
                  <div class="wrapper-info area-info">
                    <div
                      class="title-info area-title f-w3"
                      :class="getAreaName() != '...' ? 'f-w6' : ''"
                    >
                      <span class="text-pc">ピックアップエリア</span>
                      <p class="text-mobile">ピックアップ</p>
                      <p class="text-mobile">エリア</p>
                    </div>
                    <div
                      class="value-info area f-w3"
                      :class="getAreaName() != '...' ? 'f-w6' : ''"
                    >
                      {{ getAreaName() }}
                    </div>
                  </div>
                </button>
              </b-col>
            </div>
          </b-col>
          <b-modal
            id="select-time__popup"
            ref="select-time__popup"
            hide-footer
            centered
          >
            <OrderSelectTimePopup
              @close-modal="$refs['select-time__popup'].hide()"
            />
          </b-modal>
          <b-modal
            id="select-area__popup"
            ref="select-area__popup"
            hide-footer
            centered
          >
            <SelectArea-body
              @close-modal="$refs['select-area__popup'].hide()"
            />
          </b-modal>
          <b-modal
            id="select-plan__popup"
            ref="select-plan__popup"
            hide-footer
            centered
          >
            <SelectPlan-body
              @close-modal="$refs['select-plan__popup'].hide()"
            />
          </b-modal>
          <b-col
            class="btn-left"
            :class="checkEditRule ? 'disabled' : ''"
            cols="12"
            xl="6"
          >
            <button
              :disabled="checkEditRule"
              class="
                wrapper
                number-of-cast
                d-flex
                justify-content-between
                align-items-center
                text-right
              "
              :class="searchOrder.female_participants > 0 ? 'active' : ''"
              v-b-modal.select-cast-number__popup
            >
              <div class="icon-check">
                <img
                  :src="require('@/assets/image/create_order/user.svg')"
                  alt="Note"
                />
              </div>
              <div class="wrapper-info number-cast-info">
                <div
                  class="title-info number-cast-title f-w3"
                  :class="searchOrder.female_participants ? 'f-w6' : ''"
                >
                  女性Superオファー人数
                </div>
                <div
                  class="value-info number-cast f-w3"
                  :class="searchOrder.female_participants ? 'f-w6' : ''"
                >
                  {{
                    searchOrder.female_participants
                      ? searchOrder.female_participants + "人"
                      : "..."
                  }}
                </div>
              </div>
            </button>
          </b-col>
          <b-modal
            id="select-cast-number__popup"
            ref="select-cast-number__popup"
            hide-footer
            centered
          >
            <SelectCastNumber-body
              @close-modal="
                $refs['select-cast-number__popup'].hide();
                number_cast = true;
              "
            />
          </b-modal>
          <b-col class="btn-right" cols="12" xl="6">
            <button
              class="
                wrapper
                golf-course-wrap
                d-flex
                justify-content-between
                align-items-center
                text-right
              "
              :class="getGolfCourseName() != '...' ? 'active' : ''"
              v-b-modal.select-golf-course__popup
            >
              <div class="icon-check">
                <img
                  :src="require('@/assets/image/create_order/direct.svg')"
                  alt="road"
                />
              </div>
              <div class="wrapper-info golf-course-info">
                <div
                  class="title-info golf-course-title f-w3"
                  :class="getGolfCourseName() != '...' ? 'f-w6' : ''"
                >
                  ゴルフ場
                </div>
                <div
                  class="value-info golf-course f-w3"
                  :class="getGolfCourseName() != '...' ? 'f-w6' : ''"
                >
                  {{ getGolfCourseName() }}
                </div>
              </div>
            </button>
          </b-col>
          <b-modal
            id="select-golf-course__popup"
            ref="select-golf-course__popup"
            hide-footer
            centered
          >
            <SelectGolfCourse-body
              @close-modal="$refs['select-golf-course__popup'].hide()"
            />
          </b-modal>
          <b-col
            :class="checkEditRule ? 'disabled' : ''"
            class="grid-left btn-left"
            cols="6"
            xl="6"
          >
            <button
              :disabled="checkEditRule"
              id="control"
              class="
                wrapper
                prego-rank-wrap
                d-flex
                justify-content-between
                align-items-center
                text-right
              "
              :class="getPregoRankName() != '...' ? 'active' : ''"
              v-b-modal.select-prego-rank__popup
            >
              <div class="icon-check">
                <img
                  :src="require('@/assets/image/create_order/star.svg')"
                  alt="control"
                />
              </div>
              <div class="wrapper-info prego-rank-info">
                <div
                  class="title-info prego-rank-title f-w3"
                  :class="getPregoRankName() != '...' ? 'f-w6' : ''"
                >
                  ランク
                </div>
                <div
                  class="value-info prego-rank f-w3"
                  :class="getPregoRankName() != '...' ? 'f-w6' : ''"
                >
                  {{ getPregoRankName() }}
                </div>
              </div>
            </button>
          </b-col>
          <b-modal
            id="select-prego-rank__popup"
            ref="select-prego-rank__popup"
            hide-footer
            centered
          >
            <SelectPregoRank-body @close-modal="closeSelectPregoRank" />
          </b-modal>
          <b-col
            :class="
              checkEditRule || searchOrder.female_prego_ranks.length == 0
                ? 'disabled'
                : ''
            "
            class="grid-right btn-right"
            cols="6"
            xl="6"
          >
            <button
              :disabled="
                checkEditRule || searchOrder.female_prego_ranks.length == 0
              "
              id="note_star"
              class="
                wrapper
                golf-rank-wrap
                d-flex
                justify-content-between
                align-items-center
                text-right
              "
              :class="getGolfRankName() != '...' ? 'active' : ''"
              v-b-modal.select-level-golf__popup
            >
              <div class="icon-check">
                <img
                  :src="require('@/assets/image/create_order/trophy.svg')"
                  alt="note star"
                />
              </div>
              <div class="wrapper-info golf-rank-info">
                <div
                  class="title-info golf-rank-title f-w3"
                  :class="getGolfRankName() != '...' ? 'f-w6' : ''"
                >
                  ゴルフレベル
                </div>
                <div
                  class="value-info golf-rank f-w3"
                  :class="getGolfRankName() != '...' ? 'f-w6' : ''"
                >
                  {{ getGolfRankName() }}
                </div>
              </div>
            </button>
          </b-col>
          <b-col
            class="total f-w6 d-flex"
            cols="12"
            xl="12"
            v-if="checkTotal()"
          >
            <div class="total__label">合計</div>
            <div class="total__value">{{ getTotal() }}P</div>
          </b-col>
          <b-col
            class="line f-w6 d-flex"
            cols="12"
            xl="12"
            v-if="checkTotal()"
          />
          <b-modal
            id="select-level-golf__popup"
            ref="select-level-golf__popup"
            hide-footer
            centered
          >
            <SelectLevelGolf-body
              @close-modal="$refs['select-level-golf__popup'].hide()"
            />
          </b-modal>
          <b-col class="btn-left" cols="12" xl="6">
            <div class="wrapper-btn golf-situation">
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-title golf-situation-title f-w6">
                  ゴルフシチュエーション
                </div>
                <div class="multiple-selections-title f-w3">※複数選択可</div>
              </div>
              <div class="btn-style golf-situation-btn text-left f-w3">
                <button
                  v-for="(item, index) in commonData.attitudes"
                  :key="index"
                  type="button"
                  class="btn-check"
                  :class="
                    searchOrder.attitude_ids.includes(item.attitude_id)
                      ? 'active'
                      : ''
                  "
                  @click="changCheckbox('attitude_ids', item.attitude_id)"
                  :id="index"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <template v-if="!direct">
              <div class="wrapper-btn age">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-title age-title f-w6">年齢</div>
                  <div class="multiple-selections-title f-w3">※複数選択可</div>
                </div>
                <div class="btn-style age-btn text-left f-w3">
                  <button
                    v-for="(item, index) in commonData.preferredAges"
                    :key="index"
                    type="button"
                    class="btn-check"
                    :class="
                      searchOrder.preferred_age_ids.includes(
                        item.preferred_age_id
                      )
                        ? 'active'
                        : ''
                    "
                    @click="
                      changCheckbox('preferred_age_ids', item.preferred_age_id)
                    "
                    :id="index"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
              <div class="wrapper-btn type">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-title type-title f-w6">タイプ</div>
                  <div class="multiple-selections-title f-w3">※複数選択可</div>
                </div>
                <div class="btn-style type-btn text-left f-w3">
                  <button
                    v-for="(item, index) in commonData.appearances"
                    :key="index"
                    type="button"
                    class="btn-check"
                    :class="
                      searchOrder.appearance_ids.includes(item.appearance_id)
                        ? 'active'
                        : ''
                    "
                    @click="changCheckbox('appearance_ids', item.appearance_id)"
                    :id="index"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </template>
          </b-col>
          <b-col class="btn-right" cols="12" xl="6" v-if="!direct">
            <div class="wrapper-btn style">
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-title style-title f-w6">スタイル</div>
                <div class="multiple-selections-title f-w3">※複数選択可</div>
              </div>
              <div class="btn-style text-left f-w3">
                <button
                  v-for="(item, index) in commonData.figures"
                  :key="index"
                  type="button"
                  class="btn-check"
                  :class="
                    searchOrder.figure_ids.includes(item.figure_id)
                      ? 'active'
                      : ''
                  "
                  @click="changCheckbox('figure_ids', item.figure_id)"
                  :id="index"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <div class="wrapper-btn other">
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-title other-title f-w6">その他</div>
                <div class="multiple-selections-title f-w3">※複数選択可</div>
              </div>
              <div class="btn-style other-btn text-left f-w3">
                <button
                  v-for="(item, index) in commonData.otherPreferences"
                  :key="index"
                  type="button"
                  class="btn-check"
                  :class="{
                    active: searchOrder.other_preference_ids.includes(
                      item.other_preference_id
                    )
                  }"
                  @click="
                    changCheckbox(
                      'other_preference_ids',
                      item.other_preference_id
                    )
                  "
                  :id="index"
                >
                  <span v-if="item.name">{{ item.name }}</span>
                </button>
                <button
                  class="btn-check"
                  :class="searchOrder.single_participant ? 'active' : ''"
                  @click="
                    changCheckbox(
                      'single_participant',
                      searchOrder.single_participant === 0 ? 1 : 0
                    )
                  "
                >
                  一人参加OK
                </button>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-start w-100">
            </div> -->
            <hr class="solid" />
            <div class="description w-100 position-relative">
              <div class="text-left">
                <div class="description-title f-w6">
                  お相手に要望がありましたらご記載ください
                </div>
                <b-form-textarea
                  class="f-w3 description-content"
                  id="user_biography"
                  v-model="searchOrder.request"
                  placeholder="朝食をとったり、練習もしたいので早めに行きたいです！"
                  :rows="4"
                  no-resize
                >
                </b-form-textarea>
              </div>
              <!-- <div
                v-if="!searchOrder.request"
                class="position-absolute"
                style="color: #9c9c9c; top: 43px; left: 12px; font-size: 14px"
                @click="focusTextArea()"
              >
                早めに行って、朝食と練習もしたいです！
                <br />
                詳細はメッセージを頂けたら嬉しいです！
              </div> -->
            </div>
          </b-col>
          <b-col cols="12">
            <button @click="checkIdentityCreditCard()" class="btn-submit f-w3">
              次へ
            </button>
          </b-col>
        </b-row>
      </div>
      <CardFooter ref="footer" />
    </template>
    <ModalLayout ref="creditPopup" :defaultStyle="false">
      <createOrderPopup @closeModal="$refs.creditPopup.closeModal()" />
    </ModalLayout>
    <ModalLayout ref="creditIdentifyPopup" :defaultStyle="false">
      <IdentifyCrefitCardPopup
        @closeModal="$refs.creditIdentifyPopup.closeModal()"
      />
    </ModalLayout>
    <ModalLayout ref="preRankPopup" :defaultStyle="false">
      <br />
      <p class="f-w3 text-note-pregorank">
        女性のプレー代は男性がお支払いください
      </p>
      <!-- <p></p>
      <p class="f-w3 text-note-pregorank">
        ※プランで現地集合を選択した場合と個人オファーをした際は、ポイントが消費されます。
      </p>
      <p class="f-w3 text-note-pregorank">
        女性のプレー代は男性がお支払いください。
      </p> -->
    </ModalLayout>
    <ModalLayout ref="modal-confirm-take-charge">
      <p class="modal-logout-title f-w3">
        キャンセル料が発生する期間のSuperオファーとなりますがよろしいですか？
      </p>
      <p style="font-size: 14px; color: #6e6e6e">
        ※キャンセル料の発生はマッチングが完了したものに限ります
      </p>
      <div class="d-flex justify-content-center">
        <button @click.prevent="confirmTakeCharge" class="btn-logout-rtl">
          はい
        </button>
        <button @click="cancelTakeCharge" class="btn-cancel-logout-rtl">
          いいえ
        </button>
      </div>
    </ModalLayout>
  </div>
</template>

<script>
import OrderSelectTimePopup from "./OrderSelectTimePopup";
import SelectArea from "../../components/SelectArea";
import SelectPlan from "../../components/SelectPlan";
import SelectCastNumber from "../../components/SelectCastNumber";
import SelectGolfCourse from "../../components/SelectGolfCourse";
import SelectLevelGolf from "../../components/SelectLevelGolf";
import SelectPregoRank from "../../components/SelectPregoRank";
import CardFooter from "../../components/CardFooter.vue";
import ButtonBack from "../Common/ButtonBack.vue";
import NotifyHeader from "@/components/NotifyHeader.vue";
import { mapGetters } from "vuex";
import createOrderPopup from "@/components/CreateOrderPopup.vue";
import IdentifyCrefitCardPopup from "@/components/IdentifyCrefitCardPopup.vue";
import {
  COMMON_DATA,
  MESSAGES,
  ADS_STATUS_VALUE,
  ADS_STATUS_TEXT,
  RATIO_GOLF_RANK
} from "@/utils/const";
import { numberWithCommas } from "@/utils/convert";
export default {
  name: "OrderStepOne",
  // props: {
  //   profileId: {
  //     type: [String, Number]
  //   }
  // },
  components: {
    // "RoundTimeNumberCustomer-body": RoundTimeNumberCustomer,
    OrderSelectTimePopup,
    "SelectArea-body": SelectArea,
    "SelectPlan-body": SelectPlan,
    "SelectCastNumber-body": SelectCastNumber,
    "SelectGolfCourse-body": SelectGolfCourse,
    "SelectLevelGolf-body": SelectLevelGolf,
    "SelectPregoRank-body": SelectPregoRank,
    CardFooter,
    ButtonBack,
    createOrderPopup,
    IdentifyCrefitCardPopup,
    NotifyHeader
  },
  metaInfo() {
    return {
      title: "Superオファーを作成する",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      ADS_STATUS_VALUE,
      ADS_STATUS_TEXT,
      number_cast: false,
      select_time: null,
      index: null,
      openSelectTime: false,
      validate_golf_area: {
        golf_area_id: {
          text: "ピックアップエリア",
          required: true
        }
      },
      validates: {
        plan_id: {
          text: "プラン",
          required: true
        },
        male_participants: {
          text: "お客様人数",
          required: true
        },
        female_participants: {
          text: "Superオファーする女性の人数",
          required: true
        },
        scheduled_meeting_datetime: {
          text: "ラウンド日時",
          required: true
        },
        female_golf_ranks: {
          text: "ゴルフレベル",
          required: true
        },
        female_prego_ranks: {
          text: "ランク",
          required: true
        },
        golf_course_id: {
          text: "ゴルフ場",
          required: true
        }
      },
      castId: null,
      cast: {},
      direct: false,
      profileId: null
    };
  },
  methods: {
    focusTextArea() {
      document.getElementById("user_biography").focus();
    },
    checkIdentityCreditCard() {
      if (this.user.identity_status == 3 && this.cardInfo == null) {
        this.$refs["creditPopup"].openModal();
      } else if (this.user.identity_status != 3 && this.cardInfo == null) {
        this.$refs.creditIdentifyPopup.openModal();
      } else {
        const oneDay = 1000 * 60 * 60 * 24;
        let nowDate = new Date();
        let scheduleDate = this.searchOrder.scheduled_meeting_datetime;
        if (
          scheduleDate &&
          Math.round(scheduleDate.getTime() / oneDay) -
            Math.round(nowDate.getTime() / oneDay) <
            7
        ) {
          this.$refs["modal-confirm-take-charge"].openModal();
        } else {
          this.nextOrderStepTwo();
        }
      }
    },
    confirmTakeCharge() {
      this.$refs["modal-confirm-take-charge"].closeModal();
      this.nextOrderStepTwo();
    },
    cancelTakeCharge() {
      this.$refs["modal-confirm-take-charge"].closeModal();
    },
    chooseOption(type) {
      this.index = type;
    },
    changCheckbox(property, id) {
      if (property === "single_participant") {
        this.searchOrder[property] = id;
        return;
      }
      const data = [...this.searchOrder[property]];
      const index = data.indexOf(id);
      if (index > -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
      this.searchOrder[property] = [...data];
    },
    checkValidates() {
      let message = "";
      if (this.searchOrder.male_participants == 0) {
        message =
          "お客様の人数、キャスト人数、プラン、ラウンド時間、ゴルフレベル、ランクは必須です";
      }
      if (this.searchOrder.female_participants == 0) {
        message =
          "お客様の人数、キャスト人数、プラン、ラウンド時間、ゴルフレベル、ランクは必須です";
      }
      Object.keys(this.validate_golf_area).forEach(key => {
        if (this.searchOrder.plan_id && this.checkMeeting() != 2) {
          if (
            this.validate_golf_area[key].required &&
            ((this.searchOrder[key] !== 0 && !this.searchOrder[key]) ||
              this.searchOrder[key].length === 0)
          ) {
            message =
              "お客様の人数、キャスト人数、プラン、ラウンド時間、ゴルフレベル、ランクは必須です";
          }
        }
      });
      Object.keys(this.validates).forEach(key => {
        if (
          this.validates[key].required &&
          ((this.searchOrder[key] !== 0 && !this.searchOrder[key]) ||
            this.searchOrder[key].length === 0)
        ) {
          if (
            (key === "golf_course_id" &&
              this.searchOrder.golf_course_new === "") ||
            key !== "golf_course_id"
          ) {
            message =
              "お客様の人数、キャスト人数、プラン、ラウンド時間、ゴルフレベル、ランクは必須です";
          }
        }
      });
      if (
        this.searchOrder.scheduled_meeting_datetime &&
        this.$dayjs(this.searchOrder.scheduled_meeting_datetime) < this.$dayjs()
      ) {
        message =
          this.validates.scheduled_meeting_datetime.text + MESSAGES.DATE_ERROR;
      }
      if (message) {
        this.$toast(message, "通知", "danger");
        return false;
      }

      return true;
    },
    checkTotal() {
      let message = "";
      if (this.searchOrder.male_participants == 0) {
        return false;
      }
      if (this.searchOrder.female_participants == 0) {
        return false;
      }
      Object.keys(this.validate_golf_area).forEach(key => {
        // if (this.searchOrder.plan_id == 1 || this.searchOrder.plan_id == 2)
        if (
          this.validate_golf_area[key].required &&
          ((this.searchOrder[key] !== 0 && !this.searchOrder[key]) ||
            this.searchOrder[key].length === 0)
        ) {
          return false;
        }
      });
      Object.keys(this.validates).forEach(key => {
        if (
          this.validates[key].required &&
          ((this.searchOrder[key] !== 0 && !this.searchOrder[key]) ||
            this.searchOrder[key].length === 0)
        ) {
          if (
            (key === "golf_course_id" &&
              this.searchOrder.golf_course_new === "") ||
            key !== "golf_course_id"
          ) {
            message = true;
          }
        }
      });
      if (
        this.searchOrder.scheduled_meeting_datetime &&
        this.$dayjs(this.searchOrder.scheduled_meeting_datetime) < this.$dayjs()
      ) {
        return false;
      }
      if (message) {
        return false;
      }

      return true;
    },
    nextOrderStepTwo() {
      if (this.checkValidates()) {
        let params = {
          isPrevious: true
        };
        if (this.profileId) {
          params = {
            ...params,
            profileId: this.profileId
          };
        }
        if (
          this.$route.params.id &&
          this.$route.name === "MenEditOrderStepOne"
        ) {
          this.$router.push({
            name: "MenEditConfirmOrder",
            params: {
              id: this.$route.params.id,
              ...params
            }
          });
        } else {
          if (this.numberOrder != null && this.numberOrder === 0) {
            this.$router.push({ name: "MenCreateOrderStepTwo" });
          } else {
            this.$router.push({
              name: "MenCreateConfirmOrder",
              params
            });
          }
        }
      }
    },
    getAreaName() {
      if (
        !this.searchOrder.plan_id ||
        this.searchOrder.plan_id == 3 ||
        this.searchOrder.plan_id == 4
      ) {
        this.searchOrder.golf_area_id = null;
      }
      return this.searchOrder.golf_area_id
        ? this.commonData.golfAreas &&
            this.commonData.golfAreas.find(
              el => el.golf_area_id === this.searchOrder.golf_area_id
            ).name
        : "...";
    },
    getPlanName() {
      return this.searchOrder.plan_id
        ? this.commonData.plans &&
            this.commonData.plans.find(
              el => el.plan_id === this.searchOrder.plan_id
            ).name
        : "...";
    },
    getGolfCourseName() {
      if (this.searchOrder.golf_course_id) {
        return (
          (this.commonData.golfCourses &&
            this.commonData.golfCourses.find(
              el => el.golf_course_id === this.searchOrder.golf_course_id
            ).name) ||
          "..."
        );
      }
      return this.searchOrder.golf_course_new || "...";
    },
    getGolfRankName() {
      let name = "";
      const arrGolfRank = this.searchOrder.female_golf_ranks;
      this.commonData.femaleGolfRanks?.forEach(element => {
        if (arrGolfRank[0] && element.female_golf_rank_id === arrGolfRank[0]) {
          name += element.name + "\n";
        } else if (
          arrGolfRank[1] &&
          element.female_golf_rank_id === arrGolfRank[1]
        ) {
          name += element.name + "\n";
        }
      });
      return name || "...";
    },
    getPregoRankName() {
      let name = "";
      const arrPregoRank = this.searchOrder.female_prego_ranks;
      this.commonData.femalePregoRanks?.forEach(element => {
        if (
          arrPregoRank[0] &&
          element.female_prego_rank_id === arrPregoRank[0]
        ) {
          switch (element.name) {
            case "WHITE":
              name += "プレ友" + "\n";
              break;
            case "GREEN":
              name += "新人" + "\n";
              break;
            case "BLUE":
              name += "優良" + "\n";
              break;
            case "RED":
              name += "超優良" + "\n";
              break;
            default:
              if (element.name === "プレ友") {
                name += element.name + "（無料）\n";
              } else {
                name += element.name + "\n";
              }
              break;
          }
        } else if (
          arrPregoRank[1] &&
          element.female_prego_rank_id === arrPregoRank[1]
        ) {
          switch (element.name) {
            case "WHITE":
              name += "プレ友（無料）" + "\n";
              break;
            case "GREEN":
              name += "新人" + "\n";
              break;
            case "BLUE":
              name += "優良" + "\n";
              break;
            case "RED":
              name += "超優良" + "\n";
              break;
            default:
              if (element.name === "プレ友") {
                name += element.name + "（無料）\n";
              } else {
                name += element.name + "\n";
              }
              break;
          }
        }
      });
      return name || "...";
    },
    checkMeeting() {
      const plan = this.commonData.plans?.find(
        el => el.plan_id === this.searchOrder.plan_id
      );
      return plan.meeting_method;
    },
    getTotal() {
      const plan = this.commonData.plans?.find(
        el => el.plan_id === this.searchOrder.plan_id
      );
      let moneyOffer = 0;
      if (this.$route.query.offer) {
        moneyOffer = Number(plan.designation_fee);
      }
      if (!plan) return;

      // transportation cost
      let transportationCost = 0;
      if (plan.meeting_method === 2) {
        transportationCost =
          Number(plan.transportation_costs) *
          Number(this.searchOrder.female_participants);
      }

      // female golf rank 30 minutes pay
      let costGolfRank = Number.MAX_SAFE_INTEGER;
      (this.commonData.femaleGolfRanks || []).forEach(el => {
        this.searchOrder?.female_golf_ranks.forEach(id => {
          if (
            el.female_golf_rank_id === id &&
            costGolfRank > Number(el["30_minutes_pay"])
          ) {
            costGolfRank = Number(el["30_minutes_pay"]);
          }
        });
      });

      // female prego rank ratio
      let ratioReward = Number.MAX_SAFE_INTEGER;
      (this.commonData.femalePregoRanks || []).forEach(el => {
        this.searchOrder?.female_prego_ranks.forEach(id => {
          if (
            el.female_prego_rank_id === id &&
            ratioReward > Number(el["reward_ratio"])
          ) {
            ratioReward = Number(el["reward_ratio"]);
          }
        });
      });

      // menu price per person
      let menuPricePerPerson =
        Number(plan.binding_hours) *
        costGolfRank *
        RATIO_GOLF_RANK *
        ratioReward;

      // menu price
      let menuPrice = menuPricePerPerson * this.searchOrder.female_participants;

      // total amount
      let totalAmount = menuPrice + transportationCost + moneyOffer;
      localStorage.setItem("totalAmount", totalAmount);
      return numberWithCommas(totalAmount);
    },
    closeSelectPregoRank() {
      this.$refs["select-prego-rank__popup"].hide();
      // if (
      //   this.searchOrder.female_prego_ranks.includes(1) &&
      //   this.searchOrder.female_prego_ranks.length == 1
      // ) {
      //   this.$refs["preRankPopup"].openModal();
      // }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials",
      isLoading: "common/isLoading",
      cardInfo: "payment-stripe/cardInfo",
      numberOrder: "areaLimit/number_order"
    }),
    checkEditRule() {
      if (
        this.$route.name !== "MenCreateOrderDirectStepOne" &&
        !this.searchOrder.designation_user_id
      ) {
        return false;
      }
      return true;
    }
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    // get common data
    await this.$store.dispatch("common/commonsTutorials", {
      array_common_request: [
        COMMON_DATA.GOLF_AREAS,
        COMMON_DATA.PLANS,
        COMMON_DATA.APPEARANCES,
        COMMON_DATA.FIGURES,
        COMMON_DATA.GOLF_COURSES,
        COMMON_DATA.FEMALE_GOLF_RANKS,
        COMMON_DATA.FEMALE_PREGO_RANKS,
        COMMON_DATA.ATTITUDES,
        COMMON_DATA.PREFERRED_AGES,
        COMMON_DATA.OTHER_PREFERENCES
      ]
    });
    // check direct order by id on url
    if (
      this.$route.params.id &&
      this.$route.name === "MenCreateOrderDirectStepOne"
    ) {
      this.profileId = this.$route.params.id;
      this.searchOrder.female_participants = 1;
      this.direct = false;
    }

    // if create order direct => change mode display (direct) and get user info
    if (
      this.$route.params?.id &&
      this.$route.name === "MenCreateOrderDirectStepOne"
    ) {
      this.castId = this.$route.params.id;
      localStorage.setItem("direct_order", this.castId);
      await this.$store.dispatch("userApp/getInfoUser", this.castId);
      this.cast = this.$store.getters["userApp/user"].user;
      this.direct = true;
    }

    // if edit order => get order detail
    if (
      this.$route.params.id &&
      !this.searchOrder?.scheduled_meeting_datetime &&
      this.$route.name !== "MenCreateOrderDirectStepOne"
    ) {
      await this.$store.dispatch(
        "orderManagement/getOrderDetail",
        this.$route.params.id
      );
    }

    // check edit direct order style
    if (this.searchOrder.designation_user_id) {
      this.direct = true;
    }

    // get golf_rank and prego_rank by user
    if (this.profileId || this.searchOrder?.profileId) {
      const user = this.$store.getters["userApp/user"];
      this.searchOrder.female_golf_ranks = this.commonData.femaleGolfRanks
        .filter(el => el.name === user?.user?.female_golf_rank)
        .map(el => {
          return el.female_golf_rank_id;
        });
      this.searchOrder.female_prego_ranks = this.commonData.femalePregoRanks
        .filter(el => el.name === user?.user?.female_prego_rank)
        .map(el => {
          return el.female_prego_rank_id;
        });
      if (this.profileId) this.searchOrder.profileId = this.profileId;
    }
    this.$store.dispatch("common/setIsLoading", false);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // reset order in vuex
      if (localStorage.getItem("createOrder") || from.name === "CastProfile") {
        vm.$store.dispatch("orderManagement/resetOrder");
        localStorage.removeItem("createOrder");
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    // reset this.direct when route to /create-order
    if (to.name === "MenCreateOrderStepOne") {
      this.direct = false;
    }
    next();
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/men/orderStepOne.scss";
</style>

<style lang="scss" scoped>
#user_biography::-webkit-input-placeholder::before {
  content: "1st line...\A2nd line...\A3rd line...";
}
.text-note-pregorank {
  font-size: 14px;
}
.content-scroll {
  // overflow-y: scroll;
  padding-top: 42px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

.line {
  height: 5px;
  width: 100%;
  background-color: #c9c9c9;
}

.modal-logout-title {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.btn-logout-rtl {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn-cancel-logout-rtl {
  width: 155px;
  height: 45px;
  color: #000000;
  background-color: #e4e4e4;
  font-size: 16px;
  border-radius: 5px;
}

@media screen and (min-width: 1200px) {
  .text-note-pregorank {
    font-size: 16px;
  }
  .content-scroll {
    // height: calc(100vh - 152px);
    padding-top: 62px;
  }
}
</style>
