var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"area text-center"},[_c('form',{on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"area__title f-w6"},[_vm._v("ピックアップエリア")]),_c('div',{staticClass:"area__sub-title f-w3"},[_vm._v("都道府県を選択してください。")]),_c('div',{staticClass:"position-relative"},[_c('input',{staticClass:"area__input f-w3",attrs:{"readonly":""},domProps:{"value":_vm.getName()},on:{"click":function($event){return _vm.showOptions()}}})]),_c('div',{staticClass:"option f-w3"},_vm._l((_vm.commonData.golfAreas),function(option){return _c('div',{key:option.golf_area_id},[_c('p',{staticClass:"value-option",class:{
            active: _vm.area === option.golf_area_id,
            disable:
              _vm.$route.query.prefecture &&
              option.name != _vm.$route.query.prefecture,
            bold:
              _vm.$route.query.prefecture &&
              option.name == _vm.$route.query.prefecture
          },attrs:{"id":option.name},on:{"click":function($event){return _vm.chooseOption(option.golf_area_id)}}},[_vm._v(" "+_vm._s(option.name)+" ")])])}),0),_c('button',{staticClass:"btn-submit f-w3",attrs:{"type":"submit"}},[_vm._v("確定する")])])])}
var staticRenderFns = []

export { render, staticRenderFns }