<template>
  <div class="prego-rank text-center">
    <form @submit="onSubmit">
      <header>
        <div class="prego-rank__title f-w6">
          ランク
          <b-icon
            icon="question-circle"
            aria-hidden="true"
            @click="$refs.modalInfo.openModal()"
            class="question"
          ></b-icon>
        </div>
        <!-- <div class="prego-rank--note f-w3">
          ※連続する二つのランクを指定できます。
        </div> -->
      </header>
      <div v-for="(item, index) in commonData.femalePregoRanks" :key="index">
        <span
          v-if="item.is_displayed === 1"
          @click="selectedRank(index)"
          :class="{
            actived: selected === index || selectedTwo === index,
            'can-actived':
              (selected !== undefined &&
                (selected - 1 === index || selected + 1 === index)) ||
              (selectedTwo !== undefined &&
                (selectedTwo + 1 === index || selected - 1 === index)),
            disable:
              selected !== index &&
              selected + 1 !== index &&
              selected - 1 !== index &&
              selected !== undefined,
            none: index > 3
          }"
          class="gold-level-item f-w3"
        >
          <div>
            {{ item.name }}<span v-if="item.name === 'プレ友'">（無料）</span>
            <!-- <p class="mb-0 gold-level-item__description">
              {{ item.description }}
            </p> -->
          </div>
        </span>
      </div>
      <!-- <div class="text-note f-w3">※ランクにより料金の変動がございます。</div -->
      <button class="btn-submit f-w3" type="submit">確定する</button>
    </form>
    <ModalLayout ref="modalInfo">
      <div id="modal-info">
        <div class="prego-rank text-center">
          <header>
            <div class="prego-rank__title f-w6">ランクとは</div>
          </header>
          <div class="text-info f-w3">
            ランクはプレ友のみになります。<br />
            プレ友は基本料金がかかりませんので、ご安心ください。<br />
            現地集合を選択した場合や、オファー（個人に直接ゴルフの招待）をした場合は別途利用料金がかかりますので、ご了承ください。
          </div>
        </div>
      </div>
    </ModalLayout>
    <ModalLayout ref="preRankPopup" :defaultStyle="false">
      <br />
      <p class="f-w3 text-note-pregorank">
        女性のプレー代は男性がお支払いください
      </p>
      <!-- <p></p>
      <p class="f-w3 text-note-pregorank">
        ※プランで現地集合を選択した場合と個人オファーをした際は、ポイントが消費されます。
      </p>
      <p class="f-w3 text-note-pregorank">
        女性のプレー代は男性がお支払いください。
      </p> -->
    </ModalLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SelectLevelGolf",
  data() {
    return {
      selected: undefined,
      selectedTwo: undefined,
      count: 0
    };
  },
  methods: {
    selectedRank(index) {
      if (index == 0 && this.selectedTwo != index && this.selected != index) {
        this.$refs["preRankPopup"].openModal();
      }
      if (
        this.selectedTwo == undefined &&
        this.selected == undefined &&
        this.count > 1
      ) {
        this.count = 0;
      }
      if (index == this.selected) {
        this.count = 1;
      }
      if (this.count == 0) {
        this.selected = index;
        this.count++;
      } else if (
        this.count > 0 &&
        this.selectedTwo &&
        (index == this.selected + 1 ||
          index == this.selected - 1 ||
          index == this.selectedTwo + 1 ||
          index == this.selectedTwo - 1) &&
        index != this.selectedTwo
      ) {
        if (this.selected > index) {
          this.selectedTwo = this.selected;
          this.selected = index;
        } else if (this.selected == index) {
          this.selected = this.selectedTwo;
          this.selectedTwo = undefined;
        } else {
          this.selected = this.selectedTwo;
          this.selectedTwo = index;
        }
      } else if (
        this.count > 0 &&
        index != this.selected &&
        index != this.selectedTwo
      ) {
        if (index <= this.selected + 1 && index >= this.selected - 1) {
          if (this.selected < index) this.selectedTwo = index;
          else {
            this.selectedTwo = this.selected;
            this.selected = index;
          }
        }
      } else if (
        this.count > 0 &&
        (index == this.selectedTwo || index == this.selected)
      ) {
        if (index == this.selected) {
          this.selected = this.selectedTwo;
          this.count--;
        }
        this.selectedTwo = undefined;
      } else {
        this.selected = undefined;
        this.selectedTwo = undefined;
        this.count = 0;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      const arrPregoRank = [];
      if (this.selected !== undefined)
        arrPregoRank.push(
          this.commonData.femalePregoRanks[this.selected].female_prego_rank_id
        );
      if (this.selectedTwo !== undefined)
        arrPregoRank.push(
          this.commonData.femalePregoRanks[this.selectedTwo]
            .female_prego_rank_id
        );
      this.searchOrder.female_prego_ranks = arrPregoRank;
      this.$emit("close-modal", "");
    }
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials",
      authUser: "auth/user"
    })
  },
  created() {
    let arrPregoRank = this.searchOrder.female_prego_ranks;
    if (this.commonData.femalePregoRanks) {
      this.commonData.femalePregoRanks.forEach((element, index) => {
        if (
          arrPregoRank &&
          arrPregoRank[0] &&
          element.female_prego_rank_id === arrPregoRank[0]
        ) {
          this.selected = index;
          this.count = index + 1;
        } else if (
          arrPregoRank &&
          arrPregoRank[1] &&
          element.female_prego_rank_id === arrPregoRank[1]
        ) {
          this.selectedTwo = index;
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/selectPregoRank.scss";
@import "../assets/scss/_fontFamily.scss";
.question {
  cursor: pointer;
}
</style>
