<template>
  <div class="cast-number text-center">
    <form @submit="onSubmit">
      <div class="cast-number__title f-w6">
        Superオファーする女性の人数は何人ですか？
      </div>
      <div class="position-relative">
        <input
          readonly
          :value="castNumber ? castNumber + '人' : ''"
          class="cast-number__input f-w3"
          @click="showOptions()"
        />
        <!-- <b-icon
          :icon="showOption ? 'caret-up-fill' : 'caret-down-fill'"
          scale="1"
          class="position-absolute icon-input"
          @click="showOptions()"
        /> -->
      </div>
      <div class="option f-w3">
        <div v-for="index in MAX_NUMBER_OF_OPTION" :key="index">
          <p
            :class="castNumber === index ? 'active' : ''"
            class="value-option"
            @click="chooseOption(index)"
          >
            {{ index }}人
          </p>
        </div>
      </div>
      <button class="btn-submit f-w3" type="submit">
        確定する
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MAX_NUMBER_OF_OPTION } from "@/utils/const";
export default {
  name: "SelectCastNumber",
  data() {
    return {
      castNumber: 1,
      MAX_NUMBER_OF_OPTION: MAX_NUMBER_OF_OPTION
    };
  },
  methods: {
    showOptions() {
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("value-option");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.castNumber) {
            listOptions[i].style.backgroundColor = "#B9B9B9";
            listOptions[i].scrollIntoView();
          }
        }
      });
    },
    chooseOption(name) {
      this.castNumber = name;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchOrder.female_participants = this.castNumber;
      this.$emit("close-modal", "");
    }
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder"
    })
  },
  created() {
    this.castNumber = this.searchOrder.female_participants;
  }
};
</script>

<style lang="scss">
@import "../assets/scss/selectCastNumber.scss";
@import "../assets/scss/_fontFamily.scss";
</style>
