<template>
  <div class="create-order f-w3">
    <img src="../assets/image/credit-card_popup.svg" alt="" />
    <div class="note f-w3">
      Superオファーを作成するためにはクレジットカードの登録が必要です。
    </div>
    <div class="btn-wrap d-flex">
      <!-- <button class="cancel f-w3" @click="cancel">キャンセル</button> -->
      <button class="register f-w3" @click="registerCredit">登録する</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateOrderPopup",
  methods: {
    cancel() {
      this.$emit("cancelCredit");
    },
    registerCredit() {
      this.$emit("closeModal");
      if (this.$route.name != "MenRegisterCardCredit") {
        this.$router.push({ name: "MenRegisterCardCredit" });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.create-order {
  img {
    margin-top: 43.67px;
  }
  .note {
    font-size: 16px;
    line-height: 22px;
    margin-top: 29.49px;
    color: #000000;
  }
  .btn-wrap {
    margin: 35px 0 10px 0;
    button {
      height: 45px;
      width: 163px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      background-color: #e4e4e4;
    }
    .cancel {
      flex: 1;
      margin-right: 5.5px;
    }
    .register {
      flex: 1;
      background-color: #464d77;
      color: #ffffff;
    }
  }
}
@media only screen and (min-width: 1079px) {
  .create-order {
    width: 480px;
    img {
      width: 157.49px;
      height: 102.07px;
      margin-top: 61.06px;
    }
    .note {
      font-size: 18px;
      line-height: 24.5px;
      margin-top: 34.87px;
      color: #000000;
    }
    .btn-wrap {
      margin: 40px 0 30px 0;
      button {
        height: 60px;
        width: 212.5px;
        border-radius: 5px;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
        background-color: #e4e4e4;
      }
      .cancel {
        flex: 1;
        margin-right: 7.5px;
      }
      .register {
        flex: 1;
        margin-left: 7.5px;
        background-color: #464d77;
        color: #ffffff;
      }
    }
  }
}
</style>
