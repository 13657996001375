<template>
  <div class="plan text-center">
    <form @submit="onSubmit">
      <div class="plan__title f-w6 mb-0">プランを選択してください</div>
      <p style="font-size: 12px; color: #9c9c9c; margin-bottom: 25px">
        現地集合の場合は、女性の交通費として10,000ポイント消費されます
      </p>
      <div class="position-relative">
        <input
          @click="showOptions()"
          readonly
          :value="getName()"
          class="plan__input f-w3"
        />
        <!-- <b-icon
          :icon="showOption ? 'caret-up-fill' : 'caret-down-fill'"
          scale="1"
          class="position-absolute icon-input"
          @click="showOptions()"
        /> -->
      </div>
      <div class="option f-w3">
        <div v-for="(option, key) in commonData.plans" :key="option.plan_id">
          <p
            :class="getCls(key)"
            class="value-option text-left"
            @click="chooseOption(option.plan_id)"
          >
            {{ option.name }}
          </p>
        </div>
      </div>
      <button class="btn-submit f-w3" type="submit">確定する</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SelectPlan",
  data() {
    return {
      plan: "",
      showOption: true
    };
  },
  methods: {
    showOptions() {
      // this.showOption = !this.showOption;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("value-option");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.plan) {
            listOptions[i].style.backgroundColor = "#B9B9B9";
            listOptions[i].scrollIntoView();
          }
        }
      });
    },
    chooseOption(id) {
      this.plan = id;
      // this.showOption = !this.showOption;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchOrder.plan_id = this.plan;
      this.$emit("close-modal", "");
    },
    getName() {
      return (
        this.commonData.plans &&
        this.commonData.plans.find(el => el.plan_id === this.plan)?.name
      );
    },
    getCls(key) {
      let plans = this.commonData.plans;
      let cls = "";
      if (this.plan === plans[key].plan_id) {
        cls += "active";
      }

      if (plans[key].binding_hours === "8.0") {
        cls += " recommend";
      }
      return cls;
    }
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials"
    })
  },
  created() {
    this.plan = this.searchOrder.plan_id;
  }
};
</script>

<style lang="scss">
@import "../assets/scss/selectPlan.scss";
@import "../assets/scss/_fontFamily.scss";
</style>
