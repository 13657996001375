<template>
  <div class="golf-level text-center">
    <form @submit="onSubmit">
      <header>
        <div class="golf-level__title f-w6">
          ゴルフレベル
          <div class="golf-level__title--note f-w3">
            ※連続する二つのレベルを指定できます。
          </div>
        </div>
        <div class="title-option-level f-w3 d-flex justify-content-between">
          <label class="golf-rank" for="">ゴルフレベル</label>
          <!-- <label class="average value-middle" for="">アベレージ</label> -->
          <!-- <label class="basic-charge" for="">基本料金（30分）</label> -->
          <label class="basic-charge" for="">アベレージ</label>
        </div>
      </header>
      <span
        v-for="(item, index) in commonData.femaleGolfRanks"
        :key="index"
        @click="selectedRank(index)"
        :class="{
          actived: selected === index || selectedTwo === index,
          'can-actived':
            (selected !== undefined &&
              (selected - 1 === index || selected + 1 === index)) ||
            (selectedTwo !== undefined &&
              (selectedTwo + 1 === index || selected - 1 === index)),
          disable:
            selected !== index &&
            selected + 1 !== index &&
            selected - 1 !== index &&
            selected !== undefined
        }"
        class="gold-level-item f-w3"
      >
        <div>{{ item.name }}</div>
        <!-- <div class="value-middle f-w3">
          {{
            item.avg_score_standard > 140 ? "140以上" : item.avg_score_standard
          }}
        </div>
        <div class="f-w3 pay_number">
          {{ numberWithCommas(item["30_minutes_pay"]) }}P~
        </div> -->
        <div class="f-w3 pay_number">
          {{
            item.avg_score_standard > 140 ? "140以上" : item.avg_score_standard
          }}
        </div>
      </span>
      <!-- <div class="text-note f-w3">※レベルにより料金の変動がございます。</div> -->
      <button class="btn-submit f-w3" type="submit">確定する</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { numberWithCommas } from "@/utils/convert";
export default {
  name: "SelectLevelGolf",
  data() {
    return {
      selected: undefined,
      selectedTwo: undefined,
      count: 0
    };
  },
  methods: {
    selectedRank(index) {
      if (
        this.selectedTwo == undefined &&
        this.selected == undefined &&
        this.count > 1
      ) {
        this.count = 0;
      }
      if (index == this.selected) {
        this.count = 1;
      }
      if (this.count == 0) {
        this.selected = index;
        this.count++;
      } else if (
        this.count > 0 &&
        this.selectedTwo &&
        (index == this.selected + 1 ||
          index == this.selected - 1 ||
          index == this.selectedTwo + 1 ||
          index == this.selectedTwo - 1) &&
        index != this.selectedTwo
      ) {
        if (this.selected > index) {
          this.selectedTwo = this.selected;
          this.selected = index;
        } else if (this.selected == index) {
          this.selected = this.selectedTwo;
          this.selectedTwo = undefined;
        } else {
          this.selected = this.selectedTwo;
          this.selectedTwo = index;
        }
      } else if (
        this.count > 0 &&
        index != this.selected &&
        index != this.selectedTwo
      ) {
        if (index <= this.selected + 1 && index >= this.selected - 1) {
          if (this.selected < index) this.selectedTwo = index;
          else {
            this.selectedTwo = this.selected;
            this.selected = index;
          }
        }
      } else if (index == this.selectedTwo || index == this.selected) {
        if (index == this.selected) {
          this.selected = this.selectedTwo;
          this.count--;
        }
        this.selectedTwo = undefined;
      } else {
        this.selected = undefined;
        this.selectedTwo = undefined;
        this.count = 0;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      const arrGolfRank = [];
      if (this.selected !== undefined)
        arrGolfRank.push(
          this.commonData.femaleGolfRanks[this.selected].female_golf_rank_id
        );
      if (this.selectedTwo !== undefined)
        arrGolfRank.push(
          this.commonData.femaleGolfRanks[this.selectedTwo].female_golf_rank_id
        );
      this.searchOrder.female_golf_ranks = arrGolfRank;
      this.$emit("close-modal", "");
    },
    numberWithCommas
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials"
    })
  },
  created() {
    let arrGolfRank = this.searchOrder.female_golf_ranks;
    if (this.commonData.femaleGolfRanks) {
      this.commonData.femaleGolfRanks.forEach((element, index) => {
        if (
          arrGolfRank &&
          arrGolfRank[0] &&
          element.female_golf_rank_id === arrGolfRank[0]
        ) {
          this.selected = index;
          this.count = index + 1;
        } else if (
          arrGolfRank &&
          arrGolfRank[1] &&
          element.female_golf_rank_id === arrGolfRank[1]
        ) {
          this.selectedTwo = index;
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/selectLevelGolf.scss";
@import "../assets/scss/_fontFamily.scss";

.basic-charge {
  margin-right: 15px;
}
</style>
