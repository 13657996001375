<template>
  <div class="golf-course text-center">
    <form @submit="onSubmit">
      <header>
        <div class="golf-course__title f-w6">ゴルフ場を記入ください。</div>
        <div class="golf-course__popular top f-w3">
          ※1時間15分以内で到着する場所が人気です。
        </div>
        <div class="golf-course__popular f-w3">
          キャディ付きを推奨しております。
        </div>
      </header>
      <div class="reservation-status">
        <div class="reservation-status__title f-w6 text-left">予約状況</div>
        <b-form-radio-group
          class="f-w3 text-left"
          v-model="value"
          :options="optionsReservationStatus"
          name="radio-validation"
          plain
          @change="changeOption"
        >
        </b-form-radio-group>
      </div>
      <div v-if="value !== 2">
        <div class="golf-course__search">
          <span class="golf-course__input">
            <b-icon class="icon-search" icon="search" aria-hidden="true" />
            <input
              type="text"
              class="f-w3"
              v-model="golfCourseName"
              @keyup="searchGolfCourse"
              @compositionend="searchGolfCourse"
              @blur="getName()"
              id="golfCourse"
              placeholder="ゴルフ場"
            />
          </span>
        </div>
        <div class="golf-course__option f-w3 text-left">
          <div
            v-for="option in commonData.golfCourses"
            :key="option.golf_course_id"
          >
            <p
              :class="
                option.golf_course_id === golfCourse
                  ? 'value-option active'
                  : 'value-option'
              "
              @click="chooseOption(option.golf_course_id, option.name)"
            >
              {{ option.name }}
            </p>
          </div>
        </div>
        <div class="option-other f-w3 text-left">
          ※お探しのゴルフ場が見つからない場合はこちら
        </div>
        <span class="enter-golf-course">
          <input
            :disabled="golfCourse !== null && golfCourse !== ''"
            type="text"
            class="f-w3"
            placeholder="ゴルフ場を直接入力する"
            v-model="newName"
            @blur="newName = (newName || '').trim()"
            @keypress="keyUpEvent($event)"
            @input="checkInput"
          />
        </span>
        <p v-if="this.error" class="noti text-left mb-0 f-w3">
          プレーエリアを入力ください。
        </p>
      </div>
      <div v-else>
        <br />
        <span class="enter-golf-course">
          <input
            type="text"
            class="f-w3"
            placeholder="（例）千葉近郊、宇都宮付近など"
            v-model="newName"
            @blur="newName = (newName || '').trim()"
            @keypress="keyUpEvent($event)"
            @input="checkInput"
          />
        </span>
        <p v-if="this.error" class="noti text-left mb-0 f-w3">
          プレーエリアを入力ください。
        </p>
      </div>
      <button class="btn-submit f-w3" type="submit">確定する</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MAX_LENGTH_GOLF_COURSE_NAME } from "@/utils/const";
export default {
  name: "SelectGolfCourse",
  data() {
    return {
      golfCourse: null,
      golfCourseName: "",
      value: 0,
      newName: "",
      optionsReservationStatus: [
        { text: "予約済", value: 1 },
        { text: "予約予定", value: 0 },
        { text: "あとで決める", value: 2 }
      ],
      error: false
    };
  },
  methods: {
    changeOption() {
      this.golfCourse = null;
      this.golfCourseName = "";
      this.newName = "";
      this.error = false;
    },
    convertHalfSizeToFullSize(fullWidthNum) {
      return fullWidthNum.replace(/[A-Za-z0-9]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
      });
    },
    convertFullSizeToHalfSize(fullWidthNum) {
      return fullWidthNum.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
    searchGolfCourse() {
      let input = document.getElementById("golfCourse");
      let inputFullSize = this.convertHalfSizeToFullSize(input.value);
      let inputHaftSize = this.convertFullSizeToHalfSize(input.value);
      let filter1 = inputFullSize.toUpperCase();
      let filter2 = inputHaftSize.toUpperCase();
      let value = document.getElementsByClassName("value-option");
      for (let i = 0; i < value.length; i++) {
        let a = value[i];
        let txtValue = a.textContent || a.innerText;
        if (
          txtValue.toUpperCase().indexOf(filter1.trim()) > -1 ||
          txtValue.toUpperCase().indexOf(filter2.trim()) > -1
        ) {
          value[i].style.display = "";
        } else {
          value[i].style.display = "none";
        }
      }
    },
    chooseOption(id, name) {
      if (this.golfCourse === id) {
        this.golfCourse = null;
        this.golfCourseName = "";
        return;
      }
      this.golfCourse = id;
      this.golfCourseName = name;
      this.newName = "";
    },
    onSubmit(event) {
      event.preventDefault();
      if (!this.golfCourse && this.newName === "") {
        this.error = true;
      } else {
        this.searchOrder.reservation_status = this.value;
        if (this.golfCourse) {
          this.searchOrder.golf_course_id = this.golfCourse;
          this.searchOrder.golf_course_new = "";
        } else {
          this.searchOrder.golf_course_id = null;
          this.searchOrder.golf_course_new = this.newName;
        }
        this.$emit("close-modal", "");
      }
    },
    getName() {
      if (this.golfCourse) {
        this.golfCourseName =
          (this.commonData.golfCourses &&
            this.commonData.golfCourses.find(
              el => el.golf_course_id === this.golfCourse
            ).name) ||
          "";
      } else {
        this.newName = this.searchOrder.golf_course_new;
      }
    },
    keyUpEvent(event) {
      if (String(this.newName).length >= MAX_LENGTH_GOLF_COURSE_NAME) {
        event.preventDefault();
        return;
      }
    },
    checkInput(event) {
      if (
        event.target.value &&
        event.target.value.length > MAX_LENGTH_GOLF_COURSE_NAME
      ) {
        this.newName = event.target.value.substring(
          0,
          MAX_LENGTH_GOLF_COURSE_NAME
        );
        this.$forceUpdate();
      }
    }
  },
  computed: {
    ...mapGetters({
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials"
    })
  },
  created() {
    this.golfCourse = this.searchOrder.golf_course_id;
    this.getName();
    // if (this.$route.params.id && this.$route.name == "MenEditOrderStepOne") {
    if (this.searchOrder.reservation_status) {
      this.value = this.searchOrder.reservation_status;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/selectGolfCourse.scss";
@import "../assets/scss/_fontFamily.scss";

.noti {
  font-size: 12px;
  color: #ee4e4e;
  margin-top: 10px;
}
</style>
