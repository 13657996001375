var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prego-rank text-center"},[_c('form',{on:{"submit":_vm.onSubmit}},[_c('header',[_c('div',{staticClass:"prego-rank__title f-w6"},[_vm._v(" ランク "),_c('b-icon',{staticClass:"question",attrs:{"icon":"question-circle","aria-hidden":"true"},on:{"click":function($event){return _vm.$refs.modalInfo.openModal()}}})],1)]),_vm._l((_vm.commonData.femalePregoRanks),function(item,index){return _c('div',{key:index},[(item.is_displayed === 1)?_c('span',{staticClass:"gold-level-item f-w3",class:{
          actived: _vm.selected === index || _vm.selectedTwo === index,
          'can-actived':
            (_vm.selected !== undefined &&
              (_vm.selected - 1 === index || _vm.selected + 1 === index)) ||
            (_vm.selectedTwo !== undefined &&
              (_vm.selectedTwo + 1 === index || _vm.selected - 1 === index)),
          disable:
            _vm.selected !== index &&
            _vm.selected + 1 !== index &&
            _vm.selected - 1 !== index &&
            _vm.selected !== undefined,
          none: index > 3
        },on:{"click":function($event){return _vm.selectedRank(index)}}},[_c('div',[_vm._v(" "+_vm._s(item.name)),(item.name === 'プレ友')?_c('span',[_vm._v("（無料）")]):_vm._e()])]):_vm._e()])}),_c('button',{staticClass:"btn-submit f-w3",attrs:{"type":"submit"}},[_vm._v("確定する")])],2),_c('ModalLayout',{ref:"modalInfo"},[_c('div',{attrs:{"id":"modal-info"}},[_c('div',{staticClass:"prego-rank text-center"},[_c('header',[_c('div',{staticClass:"prego-rank__title f-w6"},[_vm._v("ランクとは")])]),_c('div',{staticClass:"text-info f-w3"},[_vm._v(" ランクはプレ友のみになります。"),_c('br'),_vm._v(" プレ友は基本料金がかかりませんので、ご安心ください。"),_c('br'),_vm._v(" 現地集合を選択した場合や、オファー（個人に直接ゴルフの招待）をした場合は別途利用料金がかかりますので、ご了承ください。 ")])])])]),_c('ModalLayout',{ref:"preRankPopup",attrs:{"defaultStyle":false}},[_c('br'),_c('p',{staticClass:"f-w3 text-note-pregorank"},[_vm._v(" 女性のプレー代は男性がお支払いください ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }